import React from 'react';
import ClientList from './ClientList';
import Footer from './Footer';
import Header from './Header';

const Home = () => {
    return (
        <div>
            <Header />

            <div className="slider-container">
                <div id="myCarousel" className="carousel slide banner" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="item active">
                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/banner-3.jpg`} alt="Banner 3" />
                            <div className="carousel-caption"></div>
                        </div>
                        <div className="item">
                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/banner-4.jpg`} alt="Banner 4" />
                            <div className="carousel-caption"></div>
                        </div>
                        <div className="item">
                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/banner-5.jpg`} alt="Banner 5" />
                            <div className="carousel-caption"></div>
                        </div>
                        <div className="item">
                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/banner-6.jpg`} alt="Banner 6" />
                            <div className="carousel-caption"></div>
                        </div>
                    </div>
                    <ul className="slidertabs nav-pills nav-justified">
                        <li data-target="#myCarousel" data-slide-to="0" className="active-block">
                            <a href="/#">
                                <div className="feature-list col-lg-4 col-sm-4 col-md-4 col-xs-4">
                                    <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/home-security-icon.png`} alt="Design Services" />
                                    <h3>DESIGN SERVICES</h3>
                                </div>
                            </a>
                        </li>
                        <li data-target="#myCarousel" data-slide-to="1">
                            <a href="/#">
                                <div className="feature-list col-lg-4 col-sm-4 col-md-4 col-xs-4">
                                    <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/icon2.png`} alt="System Integration" />
                                    <h3>System Integration</h3>
                                </div>
                            </a>
                        </li>
                        <li data-target="#myCarousel" data-slide-to="2">
                            <a href="/#">
                                <div className="feature-list col-lg-4 col-sm-4 col-md-4 col-xs-4">
                                    <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/icon3.png`} alt="PCB Assembly" />
                                    <h3>PCB Assembly</h3>
                                </div>
                            </a>
                        </li>
                        <li data-target="#myCarousel" data-slide-to="3">
                            <a href="/#">
                                <div className="feature-list col-lg-4 col-sm-4 col-md-4 col-xs-4">
                                    <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/icon4.png`} alt="Cable Wire Harness" />
                                    <h3>Cable Wire Harness</h3>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="welcome-container">
                    <div className="container">
                        <h1>Welcome To CBC Electronics</h1>
                        <p>CBC was established in 1993 in Daly City, California. Since it has expanded with a contract manufacturing facility in Bangalore India and manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer</p>
                        <a href="https://lifequoteshub.com/CBC/about-us/" className="red-button">READ MORE</a>
                    </div>
                </div>
            </div>

            <div className="row three-blocks no_padding no-margin">
                <div className="container">
                    <h2 className="block-title text-center">Why <span>Us?</span></h2>
                    <div className="col-md-4 col-xs-4">
                        <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/modern.jpg`} alt="modern" className="img-responsive" />
                        <h3 className="block-sub-title"><a href="/#">Competitiveness</a></h3>
                        <p>With China prices creeping up, with India’s English speaking talent pool and government’s incentivized schemes, We are commended for our competitiveness.</p>
                    </div>
                    <div className="col-md-4 col-xs-4">
                        <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/high.jpg`} alt="high" className="img-responsive" />
                        <h3 className="block-sub-title"><a href="/#">High Quality</a></h3>
                        <p>We have all necessary certifications for Industrial, Automotive and Healthcare verticals. We have adopted Six-Sigma and Kaizen</p>
                    </div>
                    <div className="col-md-4 col-xs-4">
                        <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/quick.jpg`} alt="quick" className="img-responsive" />
                        <h3 className="block-sub-title"><a href="/#">Quick Support</a></h3>
                        <p>We have offices in three time zones, you can reach a customer service person 24/7 live. Our objective is to have satisfied customers and repeat business.</p>
                    </div>
                </div>
            </div>

            <div className="tabs-blk">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="block-title text-center">know <span>more</span></h2>
                            <div className="col-lg-4">
                                <ul className="nav nav-tabs tabs-left">
                                    <li className="active">
                                        <a href="#home" data-toggle="tab">
                                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/f-1.png`} alt="What We Do" className="img-responsive" />
                                            <h3 className="block-sub-title">What We Do</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#profile" data-toggle="tab">
                                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/f-2.png`} alt="Who we are" className="img-responsive" />
                                            <h3 className="block-sub-title">Who we are</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#messages" data-toggle="tab">
                                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/f-3.png`} alt="Our Team" className="img-responsive" />
                                            <h3 className="block-sub-title">Our Team</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#settings" data-toggle="tab">
                                            <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/f-4.png`} alt="Certifications" className="img-responsive" />
                                            <h3 className="block-sub-title">Certifications</h3>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-8">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home">
                                        <h3 className="block-sub-title">What We Do</h3>
                                        <p>CBC was established in 1993 in Daly City, California. Since it has expanded with a contract manufacturing facility in Bangalore India and manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer.</p>
                                    </div>
                                    <div className="tab-pane" id="profile">
                                        <h3 className="block-sub-title">Who we are</h3>
                                        <p>CBC was established in 1993 in Daly City, California. Since it has expanded with a contract manufacturing facility in Bangalore India and manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer.</p>
                                    </div>
                                    <div className="tab-pane" id="messages">
                                        <h3 className="block-sub-title">Our Team</h3>
                                        <p>CBC was established in 1993 in Daly City, California. Since it has expanded with a contract manufacturing facility in Bangalore India and manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer.</p>
                                    </div>
                                    <div className="tab-pane" id="settings">
                                        <h3 className="block-sub-title">Certifications</h3>
                                        <p>CBC was established in 1993 in Daly City, California. Since it has expanded with a contract manufacturing facility in Bangalore India and manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientList />
            <Footer />
        </div>
    );
};

export default Home;
