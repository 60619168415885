import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Services = () => {
    return (
        <>
            <Header />
            <div className="services">
                <div className="container">
                    <div className="row">
                        <div className="span12">
                            <div className="row">
                                <div className="span12">
                                    <section className="title-section">
                                        {/* <h1 className="title-header">Contacts </h1> */}
                                        <ul className="breadcrumb breadcrumb__t">
                                            <li>
                                                <img
                                                    src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/contact-page-home.png"
                                                    alt="Home"
                                                />
                                            </li>
                                            <li className="active">Services</li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="col-lg-4">
                                    {/* Nav tabs */}
                                    <ul className="nav nav-tabs tabs-right">
                                        <li className="active">
                                            <a href="#home-r" data-toggle="tab" className="design-services">
                                                Design Services
                                                <img
                                                    src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/design-services.png"
                                                    alt="Design Services"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#profile-r" data-toggle="tab" className="system-integration">
                                                System Integration
                                                <img
                                                    src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/system-integration.png"
                                                    alt="System Integration"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#messages-r" data-toggle="tab" className="printed-cicuit-board">
                                                Printed Circuit Board Assembly
                                                <img
                                                    src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/printed-circuit-board.png"
                                                    alt="Printed Circuit Board Assembly"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#settings-r" data-toggle="tab" className="cable-wire-harness">
                                                Cable Wire Harness
                                                <img
                                                    src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/cable-wire-harness.png"
                                                    alt="Cable Wire Harness"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-8">
                                    {/* Tab panes */}
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="home-r">
                                            <h1 className="inner-pageheading">Design Services</h1>
                                            <p>We design and manufacture customized products in the Analog, Digital, RF &amp; Electrical domains.</p>
                                        </div>
                                        <div className="tab-pane" id="profile-r">
                                            <h1 className="inner-pageheading">System Integration</h1>
                                            <p>
                                                CBC provides integrated turnkey systems manufacturing solutions, utilizing manufacturing technologies to
                                                support customer manufacturing requirements. CBC focuses on quality, service, and flexibility serving the
                                                Medical, Industrial &amp; Aerospace markets.
                                            </p>
                                            <p>
                                                CBC provides the resources to maximize productivity and flexibility, control costs, and reduce time to market.
                                                These capabilities allow our customers to achieve improvements in cycle time, asset utilization, reliability, and
                                                reaction time.
                                            </p>
                                            <p>
                                                CBC's Design team enables customers to leverage our innovation capabilities - offering a win-win proposition.
                                                Customers' intellectual property is protected while leveraging our design capabilities to lower costs and improve
                                                time to market for new products.
                                            </p>
                                            <p>
                                                CBC's dedicated team offers solutions from minor product changes and design enhancements of existing products
                                                to complete turnkey design and build solutions.
                                            </p>
                                        </div>
                                        <div className="tab-pane" id="messages-r">
                                            <h1 className="inner-pageheading">Printed Circuit Board Assembly</h1>
                                            <p>
                                                CBC's facility is capable of RF, Wireless, and Microwave components. The facility has the ability to build rigid, flex,
                                                and ceramic substrate assemblies, and also provide die-bonding and wire bonding. We have multiple surface
                                                mount, thorough hole, and chip-on-board lines. We have separate RoHs and non-RoHs lines. For testing, we have
                                                AOI, ICT, FCT, X-ray inspection &amp; Flying Probe.
                                            </p>
                                        </div>
                                        <div className="tab-pane" id="settings-r">
                                            <h1 className="inner-pageheading">Cable Wire Harness</h1>
                                            <p>
                                                CBC has a vertically integrated facility for building custom wire harnesses. We have the following equipment to
                                                meet all types of customer needs:
                                            </p>
                                            <ul>
                                                <li>Automatic wire cutting machines</li>
                                                <li>Laser stripping machine</li>
                                                <li>Soldering machines</li>
                                                <li>Molding machine</li>
                                                <li>Cross section analysis machines</li>
                                                <li>Automatic wire stripping machines</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="services-rite-image"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );

}
export default Services;