import React, { useState } from 'react';
import Header from '../components/Header';
import FooterSecond from '../FooterSecond';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false); // To manage the form submission state
    const [error, setError] = useState(null); // To handle errors
    const [successMessage, setSuccessMessage] = useState(''); // To show success message

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the form while submitting
        setError(null); // Clear any previous error
        setSuccessMessage(''); // Clear any previous success message

        try {
            const response = await fetch('https://cbcpcb.com/api/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                }); // Clear form data
                console.log(result);
            } else {
                const errorResponse = await response.json();
                setError(errorResponse.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('Failed to send the message. Please try again.');
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }
    };

    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="row">
                            <div className="span12">
                                <section className="title-section">
                                    <ul className="breadcrumb breadcrumb__t">
                                        <li>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/cbcpcb/images/contact-page-home.png`}
                                                alt="Home"
                                            />
                                        </li>
                                        <li className="active">Contacts</li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div id="content" className="row">
                            <div className="span12">
                                <div id="post-14" className="post-14 page type-page status-publish hentry page">
                                    <div className="row">
                                        <div className="span4 col-lg-4">
                                            <h3 className="us-flag">Corporate Office</h3>
                                            <div className="hr-small"></div>
                                            <address>
                                                <strong>CALIFORNIA BUSINESS COMPUTING, INC.</strong><br />
                                                418 8<sup>th</sup> STREET SE – Suite-C8<br />
                                                LOVELAND CO 80537 USA<br />
                                                <br />
                                                Telephone: <a href="tel:+16694551260">669-455-1260</a><br />
                                                E-mail: <a href="mailto:sales@cbcpcb.com">sales@cbcpcb.com</a>
                                            </address>

                                            <h3 className="india-flag">Branch Office</h3>
                                            <div className="hr-small"></div>
                                            <address>
                                                <strong>CBC Electronics</strong><br />
                                                37 Nagasandra Main Road,<br />
                                                Bangalore 560073.<br />
                                            </address>
                                        </div>

                                        <div className="span8 col-lg-7">
                                            <h2>Contact form</h2>
                                            <div className="hr"></div>
                                            <form onSubmit={handleSubmit} className="wpcf7-form">
                                                <p className="field">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        size="40"
                                                        className="wpcf7-form-control"
                                                        aria-required="true"
                                                        placeholder="Name"
                                                        disabled={isSubmitting}
                                                    />
                                                </p>
                                                <p className="field">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        size="40"
                                                        className="wpcf7-form-control"
                                                        aria-required="true"
                                                        placeholder="Email"
                                                        disabled={isSubmitting}
                                                    />
                                                </p>
                                                <p className="field">
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        value={formData.subject}
                                                        onChange={handleChange}
                                                        size="40"
                                                        className="wpcf7-form-control"
                                                        placeholder="Subject"
                                                        disabled={isSubmitting}
                                                    />
                                                </p>
                                                <p className="field">
                                                    <textarea
                                                        name="message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        cols="40"
                                                        rows="10"
                                                        className="wpcf7-form-control"
                                                        placeholder="Message"
                                                        disabled={isSubmitting}
                                                    />
                                                </p>

                                                <p className="submit-wrap">
                                                    <input
                                                        type="submit"
                                                        value="Send"
                                                        className="wpcf7-form-control wpcf7-submit btn btn-primary"
                                                        disabled={isSubmitting}
                                                    />
                                                    {isSubmitting && <span className="ajax-loader"></span>}
                                                </p>
                                                {error && <p className="error-message">{error}</p>}
                                                {successMessage && <p className="success-message">{successMessage}</p>}
                                            </form>
                                        </div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSecond />
        </>
    );
};

export default Contact;
