import React from 'react';

const ClientList = () => {
    return (
        <div className="client-list">
            <div className="container">
                <div className="row">
                    <h2 className="block-title text-center">clients who <span>believe in us</span></h2>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-1.png`} alt="Client Logo 1" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-2.png`} alt="Client Logo 2" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-3.png`} alt="Client Logo 3" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-4.png`} alt="Client Logo 4" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-5.png`} alt="Client Logo 5" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-6.png`} alt="Client Logo 6" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-7.png`} alt="Client Logo 7" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 thumb">
                        <div className="logo-container">
                            <a href="/#" className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/cl-logo-8.png`} alt="Client Logo 8" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ClientList;
