import React from 'react';
import { Link } from "react-router-dom";
const FooterSecond = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-md-4 bck col-xs-6">
                                <h5>Find Us</h5>
                                <p className="home">418 8<sup>th</sup> STREET  SE – Suite-C8<br />
                                    LOVELAND CO 80537 USA</p>
                                <p className="phone"><a href="tel:+16694551260" >669-455-1260</a></p>
                                <p className="mail"><a href="mailto:sales@cbcpcb.com">sales@cbcpcb.com</a></p>
                                <ul className="social-networking">
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/fb.png`} className="twit" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/twit.png`} className="rss" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/g.png`} className="face" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-3 col-xs-6">
                                <h5>Quick Links</h5>
                                <div className="menu-footer-container">
                                    <ul id="menu-footer" className="menu">
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>

                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>

                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/contact-page`}>Contact Us</Link>

                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="row copyright-txt">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                            <p>Copyright &copy; 2024 CBC Electronics Inc. All rights reserved.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterSecond;
